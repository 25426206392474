
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import AccountService from '@/services/account-service';
import BuiltinService from '@/services/buitin-service';
import purchaseServices from '@/services/purchase-services';
import productService from '@/services/product-service';
import projectService from "@/services/project-service";
import accountService from "@/services/account-service";
import UserManagementService from "@/services/user-management";
import utils from "@/utils/utils";
import PopUp from "@/components/PopUp.vue";

@Component({
  components: {
    Header,
    SidebarPurchase,
    PopUp
  },
})
export default class PurchaseOrdersAgaintsRequisitions extends Vue {

  public document_file: any = null;
  quotation_no:any=""
  error:any=""
  loading=false;
  product_opt: any = []
  public selected_enquiries: any = {}
  public selected_requests:any={}
  items: any = [{
    item_master: {},
    uom: '',
    qty: '',
    desc: '',
    unit_rate: '',
    total_excl_vat: '',
    disc_amount: '',
    tax_amount: '',
    total_amount: '',
    net_amount: '',
    project: {},
    item_type:'',
    comment: '',
  }];
  charge_deduct_info: any = [
    {
      charges_deduction: '',
      account: {},
      ammount: '',
      charges: '',
      deduction: '',
      project: {},
      comments: '',
    }
  ]
  // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
  option_accounts: any = []
  trans: any = {};
  transaction_opt = ['5', '10', '15', '25']
  payload: any = {}
  currency_opt: any = []
  supplier_opt: any = []
  public selected_quotations:any={};
  other_info: any = {}
  selected_employees: any = []
  mounted() {
    this.retrieve()
  }
  retrieve() {
    purchaseServices.getAllCurencies().then((response) => {
      this.currency_opt = response.data
    })
  }
  getRate(data: any) {
    this.payload['transaction_rate'] = data.rate

    this.payload['transaction_id'] = data.id

  }
  sup_index = 0;
  search_supplier(title: any, index: any) {
    this.sup_index = index;
    if (title.length > 2) {
      AccountService.getSupplierAccount(title).then((response) => {
        this.supplier_opt = response.data.result
      }).catch((e) => {
        console.log(e);
      });


    }
    if (title.length == 0) {
      this.supplier_opt = [];
    }
  }
  get_data_supl(data: any) {
    this.payload.supplier = data.id;
    this.payload.request_by_title = data.title;

    this.supplier_opt = [];
  }
  save() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.payload, ['order_date', ])
    const items_error_list = utils.validateArray(this.items, ['uom', ])
    const chareges_error_list = utils.validateArray(this.charge_deduct_info, [])
    const combinedErrors = [...error_list, ...items_error_list, ...chareges_error_list];
    if (combinedErrors.length > 0) {
      this.error = utils.generateErrorMessage(combinedErrors)
    }
    else {
      
      this.payload.quotation_no = this.selected_quotations.quotation_no
    this.payload['items'] = this.items
    this.payload['other_info'] = this.other_info[0]
    this.payload['charger_deduction'] = this.charge_deduct_info.map((obj:any) => {

  if (obj.account && typeof obj.account !== 'object') {
    obj.account = {
      id: obj.account,
      title: obj.account_title
    };
    delete obj.account_title;
  }

  if (obj.project && typeof obj.project !== 'object') {
    obj.project = {
      id: obj.project,
      title: obj.project_title
    };
    delete obj.project_title;
  }
 

  return obj;
});
    purchaseServices.createPurchaseOrder(this.payload).then((resp) => {
      if (resp.status === 200 || resp.status === 201) {
        purchaseServices.uploadPurchaseOrderDoc(this.document_file, resp.data.result.id).then((response) => {
        this.showModal("Succesfully created", [], "success");
            this.reset();
            this.document_file = null;
            this.clearRow(0);
            this.clearRowCharges(0);
            for (let i = this.items.length - 1; i > 0; i--) {
              this.deleteRow(i);
            }
            for (let i = this.charge_deduct_info.length - 1; i > 0; i--) {
              this.deleteRowCharges(i);
            }
          })
      }
      else {
        this.showModal('An unexpected error occurred', [], "error");
      }
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  }

  addRow() {
    this.items.push({
      item_master: {},
      uom: '',
      qty: '',
      desc: '',
      unit_rate: '',
      total_excl_vat: '',
      disc_amount: '',
      tax_amount: '',
      total_amount: '',
      net_amount: '',
      project: {},
      item_type:'',
      comment: '',
    })
  }
  deleteRow(index: any) {
    this.items.splice(index, 1);
  }
  clearRow(index: any) {
    const item = this.items[index];
    item.item_master = {}
    item.uom = ''
    item.qty = ''
    item.desc = ''
    item.unit_rate = ''
    item.total_excl_vat = ''
    item.disc_amount = ''
    item.tax_amount = ''
    item.total_amount = ''
    item.net_amount = ''
    item.project = {}
    item.item_type=''
    item.comment = ''
  }

  addRowCharges() {
    this.charge_deduct_info.push({
      charges_deduction: '',
      account: {},
      ammount: '',
      charges: '',
      deduction: '',
      project: {},
      comments: '',
    })
  }
  deleteRowCharges(index: any) {
    this.charge_deduct_info.splice(index, 1);
  }
  clearRowCharges(index: any) {
    const item = this.charge_deduct_info[index];
    item.charges_deduction = ''
    item.account = {}
    item.ammount = ''
    item.charges = ''
    item.deduction = ''
    item.project = {}
    item.comments = ''
  }
  index = -1
  search_product_item(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {
      productService
        .getByTitle(title)
        .then((response) => {
          this.product_opt = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.product_opt = [];
    }
  }
  get_product_item(data: any) {
    this.items[this.index].item_master = data;
    this.product_opt = [];
    this.index = -1
  }
  project_index = 0
  selected_projects: any = []
  triggerList = ''
  searchProject(title: any, index: any, type: any) {

    this.triggerList = type
    this.project_index = index

    projectService.getByTitle(title).then((response) => {
      this.selected_projects = response.data.result
    })
    if (title.length == 0) {
      this.selected_projects = [];
      return
    }

  }
  get_project_data(data: any, type: any) {
    if (type !== 'charges') {
      this.items[this.project_index].project = data
    }
    else if (type === 'charges') {
      this.charge_deduct_info[this.project_index].project = data

    }
    this.selected_projects = [];
    this.triggerList = ''
  }
  account_index = -1
  search_parent_account(title: any, index: any) {
    this.account_index = index;
    if (title.length > 2) {
      accountService
        .getAccountMasterExclSeparated(title)
        .then((response) => {
          this.option_accounts = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.option_accounts = [];
    }
  }
  get_parent_account(data: any) {
    // console.log(data);
    this.charge_deduct_info[this.account_index].account = data;
    console.log(this.charge_deduct_info)
    this.option_accounts = [];
  }
  emp_index = 0;
  search_employee(title: any, index: any) {
    this.emp_index = index;
    if (title.length > 2) {
      UserManagementService.get_Employee_by_Title(title)
        .then((res) => {
          this.selected_employees = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.selected_employees = [];
    }
  }
  get_data(data: any) {
    this.payload.executive = data.id;
    this.payload.executive_title = data.title;

    this.selected_employees = [];
  }
  getQuotation(quotation_no: string) {
    this.items=[]
    this.charge_deduct_info=[]
    purchaseServices.getQuotationRequest(quotation_no).then((resp) => {
      this.selected_enquiries = resp.data.purchase_enquiry
      this.selected_requests = resp.data.purchase_request
      this.charge_deduct_info = resp.data.charges_and_deduction
      this.selected_quotations = resp.data.purchase_quotation
      this.other_info = resp.data.purchase_info
      this.payload.executive = this.selected_enquiries.executive
      this.payload.vat_type = this.selected_quotations.vat_type 
      const transaction = {
        id: this.selected_requests.transaction,
        title: this.selected_requests.transaction_title,
        rate: this.selected_requests.transaction_rate,
      }
      this.payload.transaction = transaction
      const supplier = {
        id: this.selected_quotations.supplier,
        title: this.selected_quotations.supplier_title,
      }
      this.payload.supplier = supplier
      resp.data.items.forEach((element: any) => {
        let array: any = {}
        array['item_master'] = { 'id': element['item_master'], 'title': element['item_master_title'] }
        array['project'] = { 'id': element['project'], 'title': element['project_title'] }
        array['project_title'] = element['project_title']
        array['qty'] = element['qty']
        array['uom'] = element['no_of_pieces']
        array['desc'] = element['desc']
        array['item_type'] = element['item_type']
        array['comment'] = element['comment']
        this.items.push(array)
      });
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  reset(){
    this.payload = {};
    this.error="";
    this.document_file=null;
    this.selected_enquiries={}
    this.selected_quotations={}
    this.selected_requests={}
    this.quotation_no=""
    this.items=[]
    this.charge_deduct_info=[]
    this.other_info={}
  }
  downloadDocument(url: string) {
    const anchor = document.createElement("a");
    const fileUrl = process.env.VUE_APP_SERVER + url;

    // Check if the URL is valid and the file exists
    fetch(fileUrl)
      .then(response => {
        if (response.status === 200) {
          // The file exists, so we can proceed with the download
          anchor.href = fileUrl;
          anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
          anchor.target = "_blank";

          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          this.showModal("File Downloaded",[], "success")
        } else {
          this.showModal('File not found or URL is not valid.',[], "error")
          // Handle the case where the file does not exist
        }
      })
      .catch(error => {
        // Handle other network-related errors
        this.showModal("An error occurred while fetching the file:",[], "error");
      });
  }
  getCalc(qty: number,unitRate: number,totalExcludingVAT: number,taxStructure: number,taxAmount: number,discount:number,index:number){
    if(qty !== undefined && unitRate !== undefined){
     this.items[index].total_excl_vat = utils.calculateTotalExcludingVAT(qty,unitRate)
    }
    if(totalExcludingVAT !==undefined && taxStructure !== undefined){
      this.items[index].tax_amount = utils.calculateTaxAmount(totalExcludingVAT,taxStructure)
    }
    if(totalExcludingVAT !==undefined && taxAmount !== undefined){
      this.items[index].total_amount = utils.calculateTotalAmountWithTax(totalExcludingVAT,taxAmount)
    }
    if(totalExcludingVAT !==undefined && discount !== undefined){
      this.items[index].net_amount = utils.calculateNetAmount(totalExcludingVAT,discount)
    }
  }
}
